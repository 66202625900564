<template>
  <layout>
    <div class="d-flex align-items-center justify-content-between">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item" aria-current="page">Company</li>
          <li class="breadcrumb-item" aria-current="page">{{ title }}</li>
        </ol>
      </nav>
      <div class="d-flex justify-content-between justify-content-sm-start">
        <b-button
            v-if="edit && $hasAccess(['admin', 'office_manager', 'hrm'])"
            class="ml-2"
            variant="danger"
            @click="edit = false"
        >
          Cancel
        </b-button>
        <b-button
            v-if="edit === true && $hasAccess(['admin', 'office_manager', 'hrm'])"
            class="ml-2"
            variant="primary"
            @click="handleSubmit()"
        >
          Update
        </b-button>
        <b-button
            v-if="edit === false && $hasAccess(['admin', 'office_manager', 'hrm'])"
            class="ml-2"
            variant="primary"
            @click="handleSubmit()"
        >
          Edit
        </b-button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div v-if="!edit" v-html="content"></div>
            <div v-if="edit">
              <ckeditor v-model="form.content" :editor="editor" :config="editorConfig"></ckeditor>
            </div>
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>
<script>
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';

import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials';
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold';
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link';
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import Image from '@ckeditor/ckeditor5-image/src/image';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize';
import ImageInsert from '@ckeditor/ckeditor5-image/src/imageinsert';
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload';
import ListStyle from '@ckeditor/ckeditor5-list/src/liststyle';
import HorizontalLine from '@ckeditor/ckeditor5-horizontal-line/src/horizontalline';
import Base64UploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter';
import Indent from '@ckeditor/ckeditor5-indent/src/indent';
import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock';
import Font from '@ckeditor/ckeditor5-font/src/font';



import {convertObjectToFormData} from "../../../utils/converters";
import Swal from "sweetalert2";

/**
 * Editors component
 */
export default {
  components: {
      ckeditor: CKEditor.component
  },
  data() {
    return {
      editor: ClassicEditor,
      edit: false,
      textButton: 'Edit',
      form: {
        content: null
      },
      plugins: [
        "advlist autolink link image lists charmap print preview hr anchor pagebreak spellchecker",
        "searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking",
        "save table contextmenu directionality emoticons template paste textcolor",
      ],
      toolbar:
          "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | print preview media fullpage | forecolor backcolor emoticons",
      options: {
        height: 300,
        style_formats: [
          { title: "Bold text", inline: "b" },
          { title: "Red text", inline: "span", styles: { color: "#ff0000" } },
          { title: "Red header", block: "h1", styles: { color: "#ff0000" } },
          { title: "Example 1", inline: "span", classes: "example1" },
          { title: "Example 2", inline: "span", classes: "example2" },
          { title: "Table styles" },
          { title: "Table row 1", selector: "tr", classes: "tablerow1" },
        ],
      },
      editorConfig: {
          plugins: [
              Alignment,
              EssentialsPlugin,
              BoldPlugin,
              ItalicPlugin,
              LinkPlugin,
              ParagraphPlugin,
              Base64UploadAdapter,
              Image,
              ImageInsert,
              ImageStyle,
              ImageUpload,
              ListStyle,
              HorizontalLine,
              Indent,
              IndentBlock,
              Font,
              ImageToolbar,
              ImageResize,
          ],
          fontSize: {
            options: [
              8,
              10,
              12,
              14,
              16,
              18,
              20,
              22,
              24,
              26
            ]
          },
          toolbar: {
              items: [
                  'heading',
                  '|',
                  'bold',
                  'italic',
                  'link',
                  '|',
                  "numberedlist",
                  "bulletedlist",
                  "HorizontalLine",
                  '|',
                  'outdent',
                  'indent',
                  '|',
                  'imageUpload',
                  '|',
                  'fontSize', 'fontFamily', 'fontColor', 'fontBackgroundColor',
                  '|',
                  'undo',
                  'redo',
              ]
          },
          image: {
              toolbar: [
                  'imageTextAlternative',
                  'imageStyle:inline',
                  'imageStyle:block',
                  'imageStyle:side',
              ],
              styles: [
                  'full',
                  'alignLeft',
                  'alignRight'
              ]
          },
      }
    };
  },
  mounted() {
    this.$store.dispatch('about/getAbout', { key: this.$route.params.key });
  },
  computed: {
    key() {
      return this.$route.params.key;
    },
    content() {
      return this.$store.state.about.content;
    },
    user() {
      return this.$store.state.user.user;
    },
    title() {
      const arr = {
        mission: 'Mission',
        structure: 'Structure',
        regulations: 'Regulations',
        'general-duties': 'General Duties',
        capabilities: 'Capabilities',
        'bimi-coins': 'BimiCoins Info'
      };

      return arr[this.$route.params.key];
    }
  },
  methods: {
    handleSubmit() {
      this.edit = !this.edit;
      if (this.edit) {
        this.form.content = this.content;
      }else{
        if (this.form.content !== '') {
          this.$store.dispatch('about/saveAbout', {
            key: this.$route.params.key,
            data: convertObjectToFormData({
              value: this.form.content,
              _method: 'PUT'
            })
          }).then(() => {
            Swal.fire({
                title: 'Success',
                text: 'Data was updated successfully!',
                icon: "success",
                timer: 1500
            });
          });
        }
      }
    },
  },
  watch: {
    key() {
      this.$store.dispatch('about/getAbout', { key: this.$route.params.key })
    }
  }
};
</script>
